@charset "utf-8";


// Our variables

$white-color:      #FFFFFF;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "icon"
;
